import { ModalContainer } from "./ModalContainer";

type Result =
  | "Monieswitch Black Merch"
  | "Monieswitch Orange Merch"
  | "Monieswitch White Merch"
  | "Try again"
  | "Monieswitch Voucher";

const MERCH_IMAGE_OBJ = {
  "Monieswitch Black Merch": "../images/black-merch.png",
  "Monieswitch Orange Merch": "../images/yellow-merch.png",
  "Monieswitch White Merch": "../images/white-merch.png",
};

export const ItemModal = ({
  showModal,
  closeModal,
  result,
  voucherAmount,
}: {
  showModal: boolean;
  closeModal: () => void;
  result: Result;
  voucherAmount: number | null;
}) => {
  const renderResult = () => {
    if (result === "Try again") {
      return (
        <>
          <div className="image_background">
            <img
              src="../images/sad-face.gif"
              style={{
                marginTop: 40,
                height: 300,
                width: 300,
                objectFit: "cover",
              }}
              alt="gift-item"
            />
          </div>
          <p
            style={{
              color: "#DF0B0B",
              fontWeight: 500,
              fontSize: 24,
              marginBottom: 40,
              marginTop: 30,
            }}
          >
            You&apos;ve not earned any reward, <br /> please try again.
          </p>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              onClick={closeModal}
              style={{
                borderRadius: 5,
                height: 50,
                width: 150,
                margin: 0,
                marginBottom: "3rem",
                outline: "none",
                border: 0,
                backgroundColor: "#FB9701",
                color: "white",
                display: "flex",
                gap: 10,
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              Close{" "}
              <img
                src="../images/chevron-right.svg"
                width={21}
                height={21}
                alt=""
              />
            </button>
          </div>
        </>
      );
    }

    if (result === "Monieswitch Voucher") {
      return (
        <>
          <div className="image_background">
            <img
              src="../images/money-voucher.gif"
              style={{
                marginTop: 40,
                height: 370,
                width: "100%",
                objectFit: "cover",
                zIndex: 299,
              }}
              alt="gift-item"
            />
          </div>
          <p
            style={{
              color: "#27AE60",
              fontWeight: 500,
              fontSize: 24,
              marginBottom: 24,
              marginTop: 20,
            }}
          >
            Congratulations!
          </p>

          <div className="banner-container">
            <div className="banner">
              <span className="corner top-left"></span>
              <span className="corner top-right"></span>
              <span className="corner bottom-left"></span>
              <span className="corner bottom-right"></span>
              <p
                style={{
                  color: "#2F1C00",
                  fontWeight: 500,
                }}
              >
                You&apos;ve won a {result}{" "}
                {voucherAmount && `worth ₦${voucherAmount}`}
              </p>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="image_background">
          <img
            src={MERCH_IMAGE_OBJ[result]}
            width={264}
            height={308}
            style={{ marginBottom: 40, marginTop: 40, zIndex: 299 }}
            alt="gift-item"
          />
        </div>
        <p
          style={{
            color: "#27AE60",
            fontWeight: 500,
            fontSize: 24,
            marginBottom: 24,
            marginTop: 20,
          }}
        >
          Congratulations!
        </p>

        <div className="banner-container">
          <div className="banner">
            <span className="corner top-left"></span>
            <span className="corner top-right"></span>
            <span className="corner bottom-left"></span>
            <span className="corner bottom-right"></span>
            <p
              style={{
                color: "#2F1C00",
                fontWeight: 500,
              }}
            >
              You&apos;ve won a {result}
            </p>
          </div>
        </div>
      </>
    );
  };
  return (
    <ModalContainer showModal={showModal} closeModal={closeModal}>
      <div className={`modal fast_animation ${showModal ? "show" : "hide"}`}>
        {result !== "Try again" && (
          <img
            src="../images/bubbles.gif"
            alt=""
            style={{
              width: "100%",
              height: 250,
              position: "absolute",
              top: 0,
              right: 0,
              objectFit: "cover",
              zIndex: -1,
            }}
          />
        )}
        {renderResult()}
      </div>
    </ModalContainer>
  );
};
