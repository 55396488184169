import React, { useState, useEffect, useRef } from "react";
import { ItemProps, Wheel } from "spin-wheel";
import "./style.css";
import "./style.fonts.css";
import { ItemModal } from "./components/ItemModal";

interface ImageItem {
    src: string;
    element?: HTMLImageElement;
}

interface WheelItem {
    label: string;
    backgroundColor: string;
    labelColor: string;
    image?: ImageItem;
    imageSrc?: string;
    itemLabelFontSizeMax?: string;
}

const App: React.FC = () => {
    const wheelRef = useRef<Wheel | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const [result, setResult] = useState<any>("");
    const [voucherAmount, setVoucherAmount] = useState<number | null>(null);
    const [isSpinning, setIsSpinning] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);

    const items: WheelItem[] = [
        {
            label: "Monieswitch Black Merch",
            backgroundColor: "#111111",
            labelColor: "#FFFFFF",
            imageSrc: `${process.env.PUBLIC_URL}/images/yellow-merch.png`,
        },
        {
            label: "Monieswitch Orange Merch",
            backgroundColor: "#FB9701",
            labelColor: "#ffffff",
            image: { src: "/images/yellow-merch.jpeg" },
        },
        {
            label: "Monieswitch White Merch",
            backgroundColor: "#ffffff",
            labelColor: "#000000",
            image: { src: "/images/white-merch.png" },
        },
        {
            label: "Monieswitch Voucher",
            backgroundColor: "#9D81EB",
            labelColor: "#FFFFFF",
            image: { src: "/images/voucher.png" },
        },
        {
            label: "Try again",
            backgroundColor: "#D30404",
            labelColor: "#FFFFFF",
            image: { src: "/images/try-again.png" },
        },
    ];

    const initializeWheel = () => {
        if (containerRef.current && !wheelRef.current) {
            const wheelItems: ItemProps[] = items.map((item) => ({
                label: item.label,
                backgroundColor: item.backgroundColor,
                labelColor: item.labelColor,
                image: item.imageSrc,
            }));

            wheelRef.current = new Wheel(containerRef.current, {
                items: wheelItems,
                radius: 0.95,
                isInteractive: false,
                borderColor: "#ffe5be",
                borderWidth: 20,
                lineWidth: 0,
                itemLabelFont: "Onest",
                itemLabelFontSize: 16,
                itemLabelFontWeight: "600",
                rotationSpeedMax: 300,
            });

            wheelRef.current.onRest = (event: { type: string; currentIndex: number; rotation: number }) => {
                const winner = items[event.currentIndex].label;
                setResult(winner);
                setIsSpinning(false);
                setShowModal(true);

                if (winner === "Monieswitch Voucher") {
                    const amount = generateVoucherAmount();
                    setVoucherAmount(amount);
                } else {
                    setVoucherAmount(null);
                }
            };

            setIsLoaded(true);
        }
    };

    useEffect(() => {
        initializeWheel();

        return () => {
            if (wheelRef.current) {
                wheelRef.current.stop();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generateVoucherAmount = (): number => {
        return Math.floor(Math.random() * (100 - 20 + 1) + 20) * 100;
    };

    const handleSpinClick = (): void => {
        if (wheelRef.current) {
            setResult("");
            setVoucherAmount(null);
            setIsSpinning(true);

            const randomIndex = Math.floor(Math.random() * items.length);
            wheelRef.current.spinToItem(randomIndex, 4000, true, 3, 1);
        }
    };

    return (
        <div className="app-container">
            <img className="logo" src="/images/logo.svg" alt="logo" />
            <h1 className="title">Prize Wheel</h1>
            <div className="wheel-container">
                <div className={`wheel-wrapper ${isLoaded ? "loaded" : ""} ${isSpinning ? "spinning" : ""}`}>
                    <div ref={containerRef} style={{ width: "100%", height: "100%" }} />
                    <img className="center-img" src="/images/center.svg" alt="center" />
                    <div className="wheel-pointer" />
                </div>

                <button onClick={handleSpinClick} className="spin-button" disabled={isSpinning || !isLoaded}>
                    <p>{isLoaded ? "Spin to Win!" : "Loading..."}</p>
                    <img src="/images/arrow.svg" alt="arrow" />
                </button>
            </div>
            <ItemModal
                showModal={showModal}
                closeModal={() => {
                    setShowModal(false);
                    setTimeout(() => {
                        setVoucherAmount(null);
                        setResult("");
                    }, 300);
                }}
                result={result}
                voucherAmount={voucherAmount}
            />
        </div>
    );
};

export default App;
