import React, { FC } from "react";

type IModalContainerProps = {
  children: React.ReactNode | undefined;
  showModal: boolean;
  closeModal?: () => void;
  className?: string;
};

export const ModalContainer: FC<IModalContainerProps> = ({
  children,
  showModal,
  closeModal,
  className,
}) => {
  return (
    <div
      className={`modal_container fast_animation ${className} ${
        showModal ? "show_container" : "hide_container"
      }`}
    >
      {children}
      <button className="overlay" onClick={closeModal} />
    </div>
  );
};
